import { B2B_API_INSTANCE, B2B_API_UPLOAD_INSTANCE } from "./index";

const SERVICE_VERSION: number = 1;
const CONTROLLER_NAME: string = `/user-svc/api/v${SERVICE_VERSION}/User`;
const ACCOUNT_BULK_USER_CONTROLLER_NAME: string = `/account-svc/api/v${SERVICE_VERSION}/BulkUserProfile`;
const BULK_ACCOUNT_CONTROLLER_NAME: string = `/account-svc/api/v${SERVICE_VERSION}/BulkAccount`;
const ACCOUNT_STATEMENT_CONTROLLER_NAME: string = `/account-svc/api/v${SERVICE_VERSION}/AccountStatement`;
const BULK_REGISTRATION_CONTROLLER_NAME: string = `/account-svc/api/v${SERVICE_VERSION}/BulkRegistration`;
const ACCOUNT_GROUP_CONTROLLER_NAME: string = `/account-svc/api/v${SERVICE_VERSION}/BulkChildAccountGroup`;
const BULK_CHILD_CONTROLLER_NAME: string = `/account-svc/api/v${SERVICE_VERSION}/BulkChildAccount`;

export const forgotPassword = (email: string) => {
  const postBody = {
    email,
  };
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/ForgotPassword`, postBody);
};

export const validateResetToken = (token: string) => {
  return B2B_API_INSTANCE.get(`${CONTROLLER_NAME}/ValidateResetToken?Token=${token}`);
};

export const resetPassword = (token: string, password: string) => {
  const postBody = {
    token,
    password,
  };
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/ResetPassword`, postBody);
};

export const getUserProfile = () => {
  return B2B_API_INSTANCE.get(`${CONTROLLER_NAME}/UserProfile`);
};

export const changePassword = (oldPassword: string, newPassword: string) => {
  const postBody = {
    oldPassword,
    newPassword,
  };
  return B2B_API_INSTANCE.patch(`${CONTROLLER_NAME}/ChangePassword`, postBody);
};

export const isNewEmailValid = (email: string) => {
  return B2B_API_INSTANCE.get(`${CONTROLLER_NAME}/IsNewEmailValid?email=${email}`);
};

export const getProfileForBulkId = (bulkId: string) => {
  return B2B_API_INSTANCE.get(`${ACCOUNT_BULK_USER_CONTROLLER_NAME}/GetProfileForBulkId?id=${bulkId}`);
};

export const getAccountsForBulkId = (bulkId: string) => {
  return B2B_API_INSTANCE.get(`${BULK_ACCOUNT_CONTROLLER_NAME}/GetAccountsForBulkId?id=${bulkId}`);
};

export const accountStatementForCA = (
  accountNo: string,
  statementPeriod: string,
  email: string,
  name: string,
  bulkId: string,
  referenceId: string,
  companyName: string
) => {
  const postBody = {
    channel: "B2B",
    caNo: accountNo ?? "-",
    statementPeriod: statementPeriod,
    isOwnedAccount: true,
    language: "en",
    email: email ?? "-",
    name: name ?? "-",
    bulkId: bulkId ?? "-",
    referenceId: referenceId ?? "-",
    companyName: companyName ?? "-",
  };
  return B2B_API_INSTANCE.post(`${ACCOUNT_STATEMENT_CONTROLLER_NAME}/GetAccountStatement`, postBody);
};

export const userTutorialCompleted = (userTutorialId: string) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/UserTutorialCompleted`, { userTutorialId });
};

export const validateActivateAccountToken = (token: string) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/Activate`, { token });
};

export const resendActivateLink = (token: string) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/SendNewActivationLink`, { token });
};

export const register = (email: string, password: string, name: string, identificationType: string, identificationNo: string, mobileNo: string, invitationToken?: string) => {
  const postBody = {
    email,
    password,
    name,
    identificationType,
    identificationNo,
    mobileNo,
    invitationToken,
  };
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/Register`, postBody);
};

export const setUserPreference = (preferenceCode: number, configValue: string) => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/SetUserPreference`, { preferenceCode, configValue });
};

export const editBulkAccount = (accountObject: object) => {
  return B2B_API_INSTANCE.post(`${ACCOUNT_BULK_USER_CONTROLLER_NAME}/UpdateProfileForBulkId`, accountObject);
};

export const assignGroups = (accountsNo: string[], groupId: string) => {
  const postBody = {
    accountNumbers: accountsNo,
  };
  return B2B_API_INSTANCE.post(`${ACCOUNT_GROUP_CONTROLLER_NAME}/AssignAccountsToGroup/${groupId}`, postBody);
};

export const editElectricityAccounts = (bulkId: string, childAccountsObject: object[]) => {
  const postBody = {
    data: childAccountsObject,
  };
  return B2B_API_INSTANCE.post(`${ACCOUNT_GROUP_CONTROLLER_NAME}/UpdateMultipleAccounts/${bulkId}`, postBody);
};

export const addElectricityAccounts = (bulkId: string, childAccountsObject: object[]) => {
  const postBody = {
    bulkId: bulkId,
    childAccounts: childAccountsObject,
  };
  return B2B_API_INSTANCE.post(`${BULK_CHILD_CONTROLLER_NAME}/AddElectricityAccounts`, postBody);
};

export const createGroup = (bulkId: string, groupName: string, accountNo: string[]) => {
  const postBody = {
    groupName,
    accountNumbers: accountNo,
  };
  return B2B_API_INSTANCE.post(`${ACCOUNT_GROUP_CONTROLLER_NAME}/CreateGroupForBulkId/${bulkId}`, postBody);
};

export const deleteGroup = (groupId: string) => {
  return B2B_API_INSTANCE.post(`${ACCOUNT_GROUP_CONTROLLER_NAME}/DeleteGroup/${groupId}`);
};

export const removeAccountFromGroup = (accountNo: string[], groupId: string) => {
  const postBody = {
    accountNumbers: accountNo,
  };
  return B2B_API_INSTANCE.post(`${ACCOUNT_GROUP_CONTROLLER_NAME}/RemoveAccountsFromGroup/${groupId}`, postBody);
};

export const reassignGroups = (accountsNo: string[], groupId: string) => {
  const postBody = {
    accountNumbers: accountsNo,
  };
  return B2B_API_INSTANCE.post(`${ACCOUNT_GROUP_CONTROLLER_NAME}/AssignAccountsToGroup/${groupId}`, postBody);
};

export const assignAccountsToGroup = (accountsNo: string[], groupId: string) => {
  const postBody = {
    accountNumbers: accountsNo,
  };
  return B2B_API_INSTANCE.post(`${ACCOUNT_GROUP_CONTROLLER_NAME}/AssignAccountsToGroup/${groupId}`, postBody);
};

export const removeChildAccountFromBulkAccount = (bulkId: string, accountNo: string[]) => {
  return B2B_API_INSTANCE.post(`${BULK_ACCOUNT_CONTROLLER_NAME}/RemoveChildAccountFromBulkAccount`, { bulkId, accountNo });
};

export const getElectricityAccountDetails = (accountNo: string) => {
  return B2B_API_INSTANCE.get(`${BULK_ACCOUNT_CONTROLLER_NAME}/GetAccountDetails/${accountNo}`);
};

export const getEmailByInvitationToken = (invitationToken: string) => {
  return B2B_API_INSTANCE.get(`${CONTROLLER_NAME}/GetEmailByInvitationToken?invitationToken=${invitationToken}`);
};

export const sendRegisterNotification = () => {
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/sendRegisterNotification`);
}

export const getElectricityAccountsCard = (bulkId: string) => {
  return B2B_API_INSTANCE.post(`${BULK_ACCOUNT_CONTROLLER_NAME}/GetElectricityAccountsCard/${bulkId}`);
};

export const uploadAddAccountExcel = (file: File) => {
  const formData = new FormData();
  formData.append('File', file);

  return B2B_API_UPLOAD_INSTANCE.post(`${BULK_REGISTRATION_CONTROLLER_NAME}/UploadAddAccountExcel`, formData);
};

export const uploadDeclarationForm = (file: File, oldFile?: string) => {
  const formData = new FormData();
  formData.append('File', file);
  if (oldFile)
    formData.append('fileToDelete', oldFile);

  return B2B_API_UPLOAD_INSTANCE.post(`${BULK_REGISTRATION_CONTROLLER_NAME}/UploadDeclarationForm`, formData);
};

export const getCompanyNameForBulkId = (bulkId: string) => {
  return B2B_API_INSTANCE.get(`${ACCOUNT_BULK_USER_CONTROLLER_NAME}/GetCompanyNameForBulkId?id=${bulkId}`);
};

export const getBusinessTypeForBulkId = (bulkId: string) => {
  return B2B_API_INSTANCE.get(`${ACCOUNT_BULK_USER_CONTROLLER_NAME}/GetBusinessTypeForBulkId?id=${bulkId}`);
};

export const getGroupsTableForBulkId = (bulkId: string, recordCount: number) => {
  return B2B_API_INSTANCE.post(`${ACCOUNT_GROUP_CONTROLLER_NAME}/GetGroupsTableForBulkId/${bulkId}`, { recordCount });
};

export const getAccountsNotInGroupTable = (bulkId: string, recordCount: number) => {
  return B2B_API_INSTANCE.post(`${ACCOUNT_GROUP_CONTROLLER_NAME}/GetAccountsNotInGroupTable/${bulkId}`, { recordCount });
};

export const getGroupDetails = (groupId: string) => {
  return B2B_API_INSTANCE.post(`${ACCOUNT_GROUP_CONTROLLER_NAME}/GetGroupDetails/${groupId}`);
};

export const getAccountsInGroupTable = (groupId: string, recordCount: number) => {
  return B2B_API_INSTANCE.post(`${ACCOUNT_GROUP_CONTROLLER_NAME}/GetAccountsInGroupTable/${groupId}`, { recordCount });
};

export const updateGroupName = (groupId: string, groupName: string) => {
  return B2B_API_INSTANCE.post(`${ACCOUNT_GROUP_CONTROLLER_NAME}/UpdateGroup/${groupId}`, { groupName });
};
export const getBulkIdByROC = (roc: string) => {
  return B2B_API_INSTANCE.get(`${BULK_ACCOUNT_CONTROLLER_NAME}/GetBulkIdForROC?roc=${roc}`);
};

export const validateBulkChildAccount = (accountNo: string) => {
  return B2B_API_INSTANCE.get(`${BULK_REGISTRATION_CONTROLLER_NAME}/BulkChildAccountValidation/${accountNo}`);
};

export const setNotificationRequest = () => {
  return B2B_API_INSTANCE.post(`${BULK_REGISTRATION_CONTROLLER_NAME}/GovernmentRegistrationNotificationRequest`);
};

export const registerBulkAccount = (registerObject: object) => {
  return B2B_API_INSTANCE.post(`${BULK_REGISTRATION_CONTROLLER_NAME}/RegisterBulkAccount`, registerObject);
};

export const getRegistrationApplication = () => {
  return B2B_API_INSTANCE.get(`${BULK_REGISTRATION_CONTROLLER_NAME}/GetRegistrationApplication`);
};

export const getRegistrationRecord = (registrationId: string) => {
  return B2B_API_INSTANCE.get(`${BULK_REGISTRATION_CONTROLLER_NAME}/GetRegistrationRecord/${registrationId}`);
};

export const updateRegistrationRecord = (updateRegisterObject: object) => {
  return B2B_API_INSTANCE.post(`${BULK_REGISTRATION_CONTROLLER_NAME}/UpdateRegistrationRecord`, updateRegisterObject);
};

export const getBulkRegistrationStatus = () => {
  return B2B_API_INSTANCE.get(`${BULK_REGISTRATION_CONTROLLER_NAME}/GetRegistrationStatus`);
};

export const getAutoSuggestiveAccounts = (roc: string) => {
  return B2B_API_INSTANCE.get(`${BULK_REGISTRATION_CONTROLLER_NAME}/GetAutoSuggestiveAccountsByRoc/${roc}`);
};

export const getPendingCA = (bulkId: string, recordCount: number) => {
  return B2B_API_INSTANCE.get(`${BULK_CHILD_CONTROLLER_NAME}/GetBulkChildAccountError?collectiveContractAccountNo=${bulkId}&recordCount=${recordCount}`);
};

export const removeAccountFromPendingCA = (bulkId: string, accountNo: string[]) => {
  return B2B_API_INSTANCE.post(`${BULK_ACCOUNT_CONTROLLER_NAME}/RemoveChildAccountFromBulkAccount`, { bulkId, accountNo });
};

export const submitPendingCA = (bulkId: string, selectedAccounts: object[]) => {
  const postBody = {
    bulkId,
    accounts: selectedAccounts,
  };
  return B2B_API_INSTANCE.post(`${BULK_CHILD_CONTROLLER_NAME}/PendingElectricityAccountSubmit`, postBody);
};


export const updateUserProfile = (name: string, mobileNo: string, isAdmin : boolean, EmailOpting : boolean) => {
  const postBody = {
    name,
    mobileNo,
    isAdmin,
    EmailOpting 
  };
  return B2B_API_INSTANCE.post(`${CONTROLLER_NAME}/UpdateUserProfile`, postBody);
};

export const getRegistrationDefaultValues = (rocNo: string) => {
  return B2B_API_INSTANCE.get(`${BULK_REGISTRATION_CONTROLLER_NAME}/GetRegistrationDefaultValues?rocNo=${rocNo}`);
};
