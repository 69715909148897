import _ from "lodash";

import { PAYMENT_STATUS_NAME, PAYMENT_STATUS_TYPES } from "./allPaymentsStatusType";

export const PAYMENT_REQUIRED_STATUS_NAME = {
  ...PAYMENT_STATUS_NAME,
  PAYMENTOVERDUE: "paymentOverdue",
  PAYMENTREQUIRED_ORANGE: "paymentRequiredOrange",
  NO_ACTION_REQUIRED: "noActionRequired",
};

export const PAYMENT_REQUIRED_STATUS_TYPES = {
  ...PAYMENT_STATUS_TYPES,
  "1_RED": {
    code: "1_RED",
    name: PAYMENT_REQUIRED_STATUS_NAME.PAYMENTOVERDUE,
    label: "components.filter.paymentOverdue",
    sortSequence: 8,
  },
  "1_ORANGE": {
    code: "1_ORANGE",
    name: PAYMENT_REQUIRED_STATUS_NAME.PAYMENTREQUIRED_ORANGE,
    label: "components.filter.paymentRequired",
    sortSequence: 9,
  },
  "NO_ACTION_REQUIRED": {
    code: "NO_ACTION_REQUIRED",
    name: PAYMENT_REQUIRED_STATUS_NAME.NO_ACTION_REQUIRED,
    label: "components.filter.noActionRequired",
    sortSequence: 4,
  },
};

export const PAYMENT_REQUIRED_STATUS_AS_NAME = _.mapKeys(PAYMENT_REQUIRED_STATUS_TYPES, (value:any) => value.name);
