import _ from 'lodash';
import { PAYMENT_STATUS_NAME, PAYMENTSTATUS_AS_NAME } from "src/constants/allPaymentsStatusType";
import * as billPaymentActions from '../actions/billPaymentAction';
import { IReducerAction, IReducer, IPayloadObj } from './_commonInterface';
import { paymentRequiredStatusHelper } from 'src/helpers/paymentRequiredStatusHelper';
import { PAYMENT_REQUIRED_STATUS_AS_NAME } from 'src/constants/paymentRequiredStatusType';

type NullableNumberString = number | string | null;

interface IBatch {
  extractionDate: string;
  collectiveInvoiceNo: string;
  totalTransactionRecords: number;
  totalTransactionAmount: number;
  currentCharge: number;
}

interface IBank {
  bankId: string;
  bankCode: string;
  bankDescription: string;
}

interface IPaymentTransaction {
  transactionNo: string;
  paymentDate: string;
  transactionAmount: NullableNumberString;
  modeofPaymentDescription: string;
  paymentChannel: string;
  status: string;
  child: {
    accountNo: string;
    referenceNo: string;
    collectiveInvoiceNo: string;
    invoiceNo: string;
    transactionAmount: NullableNumberString;
  }[];
}

interface IPaymentResult {
  transactionNo: string;
  paymentDate: string;
  transactionAmount?: number;
  paidAmount?: number;
  creditedAmount?: number;
  paymentMethod?: string;
  selectedElectricityAccount?: string;
  status: string;
  totalInvoices?: number;
  errorMessage?: string;
  isInsufficientRecon?: boolean;
  isExcessiveRecon?: boolean;
  fpxForm?: object;
  isDuplicate?: boolean;
}

interface IPaymentActivity {
  transactionNo: string;
  status: string;
  paymentMethod: string;
  isInsufficient?: boolean;
  billedAmount: NullableNumberString;
  reconciliationAmount: NullableNumberString;
  paidAmount: NullableNumberString;
  paymentActivityHistory: object[];
  paymentActivityInit: {
    activityDateInit: string;
    modeofPaymentDescription: string;
    transactionAmount: number;
  };
}

interface IPaymentMethod {
  modeofPaymentCode: string;
  modeofPaymentDescription: string;
  isActive: boolean;
  default: boolean;
}

interface IElectricityAccount {
  contractAccountNo: string;
  invoiceNo: string;
}

interface IOutstandingCharges {
  contractAccountNo: string;
  reference: string;
  collectiveInvoiceNo: string;
  outstandingAmount: number;
  status: string;
}

interface ICustomSearchValue {
  searchBy: string;
  searchValue: string;
}

interface IBill {
  currentCharges: NullableNumberString;
  totalOutstandingCharges: NullableNumberString;
  totalAmountPayable: NullableNumberString;
  billHistoryBatch: IBatch[],
  unpaidBillBatch: IBatch[],
  billHistoryCount: number;
  unpaidBillCount: number;
  outstandingCharges: IOutstandingCharges[];
  outstandingChargesCount: 0;
  outstandingBills: object[],
  selectedOutstandingBills: object[],
  selectedGroupsForOutstandingBills: string[],
  selectedPaymentType: string,
  outstandingBillsCount: number;
  searchBills: object[],
  searchBillsCount: number;
  selectedPayOutstandingBill?: object;
  selectedBillStatus?:string;
  customSearchValue: ICustomSearchValue | null;
}

interface IPayment {
  totalAmountPaid: NullableNumberString;
  paymentsInProcess: NullableNumberString;
  successfulPayments: NullableNumberString;
  unsuccessfulPayments: NullableNumberString;
  virtualAccountNo: string;
  banks: IBank[];
  electricityAccounts: IElectricityAccount[];
  allPaymentMethods: IPaymentMethod[];
  paymentTransactions: IPaymentTransaction[];
  paymentTransactionCount: number;
  paymentTransaction: IPaymentTransaction;
  paymentResult: IPaymentResult;
  paymentActivity: IPaymentActivity;
}

interface IBillPaymentReducer {
  bill: IBill;
  payment: IPayment;
}


const defaultState: IBillPaymentReducer = {
  bill: {
    currentCharges: '-',
    totalOutstandingCharges: '-',
    totalAmountPayable: '-',
    billHistoryBatch: [],
    unpaidBillBatch: [],
    billHistoryCount: 0,
    unpaidBillCount: 0,
    outstandingCharges: [],
    outstandingChargesCount: 0,
    outstandingBills: [],
    selectedOutstandingBills: [],
    selectedGroupsForOutstandingBills: [],
    selectedPaymentType: '',
    outstandingBillsCount: 0,
    searchBills: [],
    searchBillsCount: 0,
    selectedPayOutstandingBill: undefined,
    customSearchValue: null,
    selectedBillStatus:undefined
  },
  payment: {
    totalAmountPaid: '-',
    paymentsInProcess: '-',
    successfulPayments: '-',
    unsuccessfulPayments: '-',
    virtualAccountNo: '-',
    banks: [],
    electricityAccounts: [],
    allPaymentMethods: [],
    paymentResult: {
      transactionNo: '-',
      paymentDate: '-',
      status: '-',
    },
    paymentTransaction: {
      transactionNo: '-',
      paymentDate: '-',
      modeofPaymentDescription: '-',
       paymentChannel: '-',
      transactionAmount: '-',
      status: '-',
      child: []
    },
    paymentTransactions: [],
    paymentTransactionCount: 0,
    paymentActivity: {
      transactionNo: '-',
      status: '-',
      paymentMethod: '-',
      isInsufficient: undefined,
      billedAmount: '-',
      reconciliationAmount: '-',
      paidAmount: '-',
      paymentActivityHistory: [],
      paymentActivityInit: {
        activityDateInit: '',
        modeofPaymentDescription: '',
        transactionAmount: 0,
      }
    }
  }
}

const REDUCERS: IReducer = {
  [billPaymentActions.GET_OUTSTANDINGBILLSCARD]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      bill: {
        ...state.bill,
        currentCharges: _.get(payload, 'currentCharges') ?? '-',
        totalOutstandingCharges: _.get(payload, 'totalOutstandingCharges') ?? '-',
        totalAmountPayable: _.get(payload, 'totalAmountPayable') ?? '-',
      }
    };
  },
  [billPaymentActions.GET_OUTSTANDINGCHARGES]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    const { outstandingCharges, count } = payload;
    return {
      ...state,
      bill: {
        ...state.bill,
        outstandingCharges: [...outstandingCharges],
        outstandingChargesCount: _.isNull(count) ? _.get(outstandingCharges, 'length') : count,
      }
    };
  },
  [billPaymentActions.GET_OUTSTANDING_BILLS]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    const { outstandingBills, count } = payload;

    //set default selection
    const { selectedOutstandingBills, selectedPayOutstandingBill,selectedBillStatus } = state.bill;

    if (selectedOutstandingBills.length > 0) {
      const preselections = _.intersectionBy(outstandingBills, selectedOutstandingBills, "invoiceNo");
      _.forEach(preselections, p => {
        if ((_.includes([PAYMENTSTATUS_AS_NAME[PAYMENT_STATUS_NAME.PAYMENTREQUIRED].code], _.get(p, "status")) &&
        _.toNumber(_.get(p, 'totalAmountPayable') ?? -1) > 0)) 
        {
          _.set(p, "isSelected", true);
        }
        else {
          _.remove(selectedOutstandingBills, o => _.get(o, 'invoiceNo') === _.get(p, 'invoiceNo'));
        }
      });
    }
    else if (selectedPayOutstandingBill) {
      const preselections = _.filter(outstandingBills, (o:any) => o.collectiveInvoiceNo === _.get(selectedPayOutstandingBill, "collectiveInvoiceNo"));
      _.forEach(preselections, p => {
        if ((_.includes([PAYMENTSTATUS_AS_NAME[PAYMENT_STATUS_NAME.PAYMENTREQUIRED].code], _.get(p, "status")) &&
          _.toNumber(_.get(p, 'totalAmountPayable') ?? -1) > 0) && !_.get(p,"disablePayment")) 
        {
          _.set(p, "isSelected", true);
        }
      });
    }
    //check checkbox based on filter condition
    else if(selectedBillStatus)
    {
      const preselections = _.filter(outstandingBills, (o:any) =>paymentRequiredStatusHelper( o.status,o.extractionDateTime,o.totalAmountPayable ) === PAYMENT_REQUIRED_STATUS_AS_NAME[selectedBillStatus].code );
      _.forEach(preselections, p => {
        if ((_.includes([PAYMENTSTATUS_AS_NAME[PAYMENT_STATUS_NAME.PAYMENTREQUIRED].code], _.get(p, "status")) &&
          _.toNumber(_.get(p, 'totalAmountPayable') ?? -1) > 0) && !_.get(p,"disablePayment")) 
        {
          _.set(p, "isSelected", true);
        }
      });
      
    }

    _.forEach(outstandingBills, b => {
      _.set(b, "extractionDateTime", _.isNull(b.extractionDateTime) ? "-" : b.extractionDateTime + "Z");
      _.set(b, "status", b.status ? paymentRequiredStatusHelper(b.status, b.extractionDateTime, b.totalAmountPayable) : "-");
    });

    return {
      ...state,
      bill: {
        ...state.bill,
        outstandingBills: [...outstandingBills],
        outstandingBillsCount: _.isNull(count) ? _.get(outstandingBills, 'length') : count,
        selectedOutstandingBills: selectedOutstandingBills
      }
    };
  },
  [billPaymentActions.SET_SELECTED_BILLS_BILL_BATCH]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    const { billBatchId } = payload;
    
    const targetRow = _.find(state.bill.outstandingCharges, o => {
      return _.get(o, 'collectiveInvoiceNo') === billBatchId;
    });

    return {
      ...state,
      bill: {
        ...state.bill,
        selectedPayOutstandingBill: targetRow,
      }
    };
  },
  [billPaymentActions.SET_SELECTED_BILLS_BILL_STATUS]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    const { billBatchFilterType,billBatchStatus } = payload;

    return {
      ...state,
      bill: {
        ...state.bill,
        selectedBillFilterType:billBatchFilterType,
        selectedBillStatus: billBatchStatus,
      }
    };
  },
  [billPaymentActions.SET_SELECTED_BILLS]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    const { invoiceNo, isSelect } = payload;
    
    const targetRow = _.find(state.bill.outstandingBills, o => _.get(o, 'invoiceNo') === invoiceNo);
    if (targetRow)
      _.set(targetRow, "isSelected", isSelect);

    return {
      ...state,
      bill: {
        ...state.bill,
        outstandingBills: [...state.bill.outstandingBills]
      }
    };
  },
  [billPaymentActions.SAVE_SELECTED_BILLS]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    const { bills } = payload;
    return {
      ...state,
      bill: {
        ...state.bill,
        selectedOutstandingBills: [...bills]
      }
    };
  },
  [billPaymentActions.SELECT_PAYMENTTYPE]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    const { type } = payload;
    return {
      ...state,
      bill: {
        ...state.bill,
        selectedPaymentType: type
      }
    };
  },
  [billPaymentActions.CLEAR_OUTSTANDING_BILLS]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      bill: {
        ...state.bill,
        outstandingBills: []
      }
    };
  },
  [billPaymentActions.GET_UNPAIDBILLHISTORY]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    const { billsByBatch, count } = payload;
    return {
      ...state,
      bill: {
        ...state.bill,
        unpaidBillBatch: _.map(billsByBatch, b => ({
          ...b,
          extractionDateTime: _.isNull(b.extractionDateTime) ? "-" : b.extractionDateTime + "Z"
        })),
        unpaidBillCount: _.isNull(count) ? _.get(billsByBatch, 'length') : count
      }
    };
  },
  [billPaymentActions.GET_BILLHISTORY]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    const { billHistory, count } = payload;
    return {
      ...state,
      bill: {
        ...state.bill,
        billHistoryBatch: _.map(billHistory, b => ({
          ...b,
          extractionDateTime: _.isNull(b.extractionDateTime) ? "-" : b.extractionDateTime + "Z"
        })),
        billHistoryCount: _.isNull(count) ? _.get(billHistory, 'length') : count
      }
    };
  },
  [billPaymentActions.SEARCH_BILLS]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    const { bills, count } = payload;
    _.forEach(bills, b => {
      _.set(b, "extractionDate", _.isNull(b.extractionDate) ? "-" : b.extractionDate + "Z");
      _.set(b, "status", b.status ? paymentRequiredStatusHelper(b.status, b.extractionDate, b.totalAmountPayable) : "-");
    });
    return {
      ...state,
      bill: {
        ...state.bill,
        searchBills: [...bills],
        searchBillsCount: _.isNull(count) ? _.get(bills, 'length') : count
      }
    };
  },
  [billPaymentActions.GET_BANKLIST]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      payment: {
        ...state.payment,
        banks: payload
      }
    };
  },
  [billPaymentActions.GET_VIRTUALACCOUNTNO]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      payment: {
        ...state.payment,
        virtualAccountNo: payload ?? "-"
      }
    };
  },
  [billPaymentActions.GET_PAYMENTMETHODS]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    _.forEach(payload, p => {
      _.set(p, "unavailableStartTime", p.unavailableStartTime ? new Date(p.unavailableStartTime + "Z") : undefined);
      _.set(p, "unavailableEndTime", p.unavailableEndTime ? new Date(p.unavailableEndTime + "Z") : undefined);
    });
    return {
      ...state,
      payment: {
        ...state.payment,
        allPaymentMethods: payload,
      }
    };
  },
  [billPaymentActions.GET_TRANSACTIONSCARDS]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      payment: {
        ...state.payment,
        totalAmountPaid: _.get(payload, 'totalAmountPaid') ?? '-',
        paymentsInProcess: _.get(payload, 'paymentsInProcess') ?? '-',
        successfulPayments: _.get(payload, 'successfulPayments') ?? '-',
        unsuccessfulPayments: _.get(payload, 'unsuccessfulPayments') ?? '-',
      }
    };
  },
  [billPaymentActions.MAKE_PAYMENT]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      payment: {
        ...state.payment,
        paymentResult: {
          transactionNo: _.get(payload, 'transactionNo') ?? '-',
          paymentDate: (_.isNull(payload) || _.isNull(payload.paymentDate)) ? "-" : payload.paymentDate + "Z",
          transactionAmount: _.get(payload, 'transactionAmount') ?? '-',
          paymentMethod: _.get(payload, 'paymentMethod') ?? '-',
          totalInvoices: _.get(payload, 'totalInvoices') ?? '-',
          status: _.get(payload, 'status'),
          fpxForm: _.get(payload, 'fpxModel')
        }
      },
      bill: {
        ...state.bill,
        selectedOutstandingBills: []
      }
    };
  },
  [billPaymentActions.MAKE_PAYMENT_FAILED]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      payment: {
        ...state.payment,
        paymentResult: {
          errorMessage: _.get(payload, 'errorMessage'),
          isDuplicate: _.get(payload, 'isDuplicate')
        }
      }
    };
  },
  [billPaymentActions.GET_PAYMENTTRANSACTIONS]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    const { paymentTransactions, count } = payload;
    _.forEach(paymentTransactions, p => {
      _.set(p, "paymentDate", _.isNull(p.paymentDate) ? "-" : p.paymentDate + "Z");
    });
    return {
      ...state,
      payment: {
        ...state.payment,
        paymentTransactions: [...paymentTransactions],
        paymentTransactionCount: _.isNull(count) ? _.get(paymentTransactions, 'length') : count,
      }
    };
  },
  [billPaymentActions.GET_PAYMENTDETAILS]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      payment: {
        ...state.payment,
        paymentTransaction: {
          transactionNo: _.get(payload, 'transactionNo') ?? '-',
          paymentDate: _.get(payload, 'paymentDate') && !_.isNull(payload.paymentDate) ? new Date(payload.paymentDate + "Z") : '-',
          transactionAmount: _.get(payload, 'transactionAmount') ?? '-',
          modeofPaymentDescription: _.get(payload, 'paymentMethod') ?? '-',
          paymentChannel:_.get(payload, 'paymentChannel') ?? '-',
          status: _.get(payload, 'status'),
          child: _.get(payload, 'child') ? [...payload.child] : [],
        }
      }
    };
  },
  [billPaymentActions.GET_PAYMENTACTIVITY]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    const isInsufficient = _.get(payload, "paymentActivityLatestReconcileRequest.latestReconcileType") === "1";
    const billedAmount = _.get(payload, "paymentActivityHead.transactionAmount");
    const reconciliationAmount = _.get(payload, "paymentActivityLatestReconcileRequest.latestReconcileAmount");
    
    let paymentActivityHistory:any[] = [];
    if (payload.paymentActivityInsufficient && payload.paymentActivityInsufficient.length > 0) {
      paymentActivityHistory.push(..._.map(payload.paymentActivityInsufficient, (p:any) => {
        return {
          activityDateTime: new Date(p.activityDateInsufficient + "Z"),
          paymentMethod: p.modeofPaymentDescriptionInsufficient,
          reconciliationAmount: p.reconciliationAmountInsufficient,
          paidAmount: p.paidAmountInsufficient ?? "-",
          electricityAccountNo: null,
          creditedAmount: null,
          billedAmount: null,
          isInsufficient: true
        }
      }));
    }
    
    if (payload.paymentActivityExcessive && payload.paymentActivityExcessive.length > 0) {
      paymentActivityHistory.push(..._.map(payload.paymentActivityExcessive, (p:any) => {
        return {
          activityDateTime: new Date(p.activityDateExcessive + "Z"),
          paymentMethod: null,
          reconciliationAmount: null,
          paidAmount: null,
          electricityAccountNo: p.contractAccountExcessive ?? "-",
          creditedAmount: p.amountExcessive ?? "-",
          billedAmount: null,
          isInsufficient: false
        }
      }));
    }
    paymentActivityHistory = _.orderBy(paymentActivityHistory, ["activityDateTime"], ["desc"]);
    const lastActivityItem = _.last(paymentActivityHistory);
    if (lastActivityItem) {
      const initPaid = payload.paymentActivityInit.transactionAmount + (lastActivityItem.creditedAmount ?? (-lastActivityItem.reconciliationAmount));
      paymentActivityHistory.push({
        activityDateTime: new Date(payload.paymentActivityInit.activityDateInit + "Z"),
        paymentMethod: payload.paymentActivityInit.modeofPaymentDescription,
        reconciliationAmount: null,
        paidAmount: initPaid,
        electricityAccountNo: null,
        creditedAmount: null,
        billedAmount: payload.paymentActivityInit.transactionAmount,
        isInsufficient: null
      });
    }
    return {
      ...state,
      payment: {
        ...state.payment,
        paymentActivity: {
          transactionNo: _.get(payload, "transactionNo"),
          status: _.get(payload, "paymentActivityHead.status"),
          paymentMethod: _.get(payload, "paymentActivityHead.modeofPaymentDescription"),
          isInsufficient: isInsufficient,
          billedAmount: Number(billedAmount),
          reconciliationAmount: Number(reconciliationAmount),
          paidAmount: Number(billedAmount) + (Number(reconciliationAmount) * (isInsufficient ? -1 : 1)),
          paymentActivityHistory: paymentActivityHistory,
          paymentActivityInit: {
            ...payload.paymentActivityInit,
            activityDateInit: payload.paymentActivityInit.activityDateInit + "Z"
          },
        } 
      }
    };
  },
  [billPaymentActions.MAKE_RECONCILIATIONPAYMENTINSUFFICIENT]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      payment: {
        ...state.payment,
        paymentResult: {
          transactionNo: _.get(payload, 'transactionNo') ?? '-',
          paymentDate: (_.isNull(payload) || _.isNull(payload.paymentDate)) ? "-" : payload.paymentDate + "Z",
          paidAmount: _.get(payload, 'reconciliationAmount') ?? '-',
          paymentMethod: _.get(payload, 'paymentMethod') ?? '-',
          status: _.get(payload, 'status'),
          isInsufficientRecon: true
        }
      }
    };
  },
  [billPaymentActions.MAKE_RECONCILIATIONPAYMENTINSUFFICIENT_FAILED]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      payment: {
        ...state.payment,
        paymentResult: {
          errorMessage: _.get(payload, 'errorMessage'),
          isInsufficientRecon: true
        }
      }
    };
  },
  [billPaymentActions.MAKE_EXCESSIVERECONCILIATIONPAYMENT]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      payment: {
        ...state.payment,
        paymentResult: {
          transactionNo: _.get(payload, 'transactionNo') ?? '-',
          paymentDate: (_.isNull(payload) || _.isNull(payload.paymentDate)) ? "-" : payload.paymentDate + "Z",
          creditedAmount: _.get(payload, 'reconciliationAmount') ?? '-',
          selectedElectricityAccount: _.get(payload, 'contractAccountNo') ?? '-',
          status: _.get(payload, 'status'),
          isInsufficientRecon: false
        }
      }
    };
  },
  [billPaymentActions.MAKE_EXCESSIVERECONCILIATIONPAYMENT_FAILED]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      payment: {
        ...state.payment,
        paymentResult: {
          errorMessage: _.get(payload, 'errorMessage'),
          isInsufficientRecon: false
        }
      }
    };
  },
  [billPaymentActions.GET_ELECTRICITYACCOUNT]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      payment: {
        ...state.payment,
        electricityAccounts: _.map((_.get(payload, 'electricityAccountListModels') ?? []), 
          (ea:any) => {
            return {
            ...ea,
            label: `${_.get(ea, "contractAccountNo")} / ${_.get(ea, "invoiceNo")}`
          }})
      }
    };
  },
  [billPaymentActions.SET_SELECTED_GROUPS_LIST]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    return {
      ...state,
      bill: {
        ...state.bill,
        selectedGroupsForOutstandingBills: payload,
      }
    };
  },
  [billPaymentActions.SET_CUSTOM_SEARCH_IN_UNPAID]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {
    const { searchBy, searchValue } = payload;
  
    return {
      ...state,
      bill: {
        ...state.bill,
        customSearchValue: {
          searchBy,
          searchValue,
        },
      }
    };
  },
  [billPaymentActions.CLEAR_CUSTOM_SEARCH_IN_UNPAID]: (state: IBillPaymentReducer, { payload }: IPayloadObj) => {  
    return {
      ...state,
      bill: {
        ...state.bill,
        customSearchValue: null,
      }
    };
  },
}

const billPaymentReducer = (state: IBillPaymentReducer = defaultState, { type, payload, error }: IReducerAction = { type: '' }) => {
  if (!type)
    return state;

  if (REDUCERS[type]) {
    return REDUCERS[type](state, { payload, error });
  }
  return state;
}
export default billPaymentReducer;
export const REDUCER_BILL_PAYMENT = 'billPaymentReducer'
